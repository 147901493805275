<template>
    <div class="d-flex justify-content-center">
      <i class="me-2 fa fa-spinner fa-spin"></i>
      <!-- <img class="" src="assets/images/loader/Spinner-3.gif" alt="Loader" title="Loader" > -->
    </div>
</template>
<script>
import "vue-skeletor/dist/vue-skeletor.css";
import { Skeletor } from "vue-skeletor";

export default {
  name: "App",
  components: {
    Skeletor,
  },
};
</script>

<!-- <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.card {
  width: 360px;
  margin: 0 auto;
}

.loading_image {
  
  margin-bottom: 12px;
}

.card__slim {
  display: flex;
  margin-bottom: 12px;
}

.card__avatar {
  margin-right: 12px;
}

.card__meta {
  flex-grow: 1;
}

.card__meta p,
.card__meta h2 {
  margin: 0;
}

.card__text {
  margin-bottom: 12px;
}

.card__actions {
  display: flex;
  justify-content: flex-end;
}
</style> -->