<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement innerPage-banner bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>News</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/dashboard">Home</router-link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">News</li>
                                    </ol>
                                </nav>
                            </div>
                            <ul class="toplinks d-flex align-items-center mb-0 py-2 mainTabsList">
                                <li class="ps-0" @click="mainTab = 'All';marketTab = 'All';page= 1;clearCountries();allnews()"><a
                                        href="javascript:void(0)" class="medium"
                                        :class="[{'active' : mainTab == 'All'}]">All</a></li>
                                <li @click="mainTab = 'trending';;marketTab = 'All';page= 1;clearCountries();allnews()"><a class="medium"
                                        href="javascript:void(0)" :class="[{'active' : mainTab  =='trending'}]">Trending
                                        News</a> </li>
                                <li @click="mainTab = 'mostRead';marketTab = '';page= 1;allnews()"><a class="medium"
                                        href="javascript:void(0)" :class="[{'active' : mainTab == 'mostRead'}]">Most
                                        Read</a> </li>
                                <li @click="mainTab = 'popular';marketTab = '';page= 1;allnews()"><a class="medium"
                                        href="javascript:void(0)" :class="[{'active' : mainTab == 'popular'}]">Most
                                        Popular</a> </li>
                            </ul>
                            <div class="rightSearch hideMobile">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <!-- <input type="text" class="form-control" placeholder="Search.." name="" />
                                        <a class="searchButton" href="#">
                                            <vue-feather type="search"></vue-feather>
                                        </a> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement newsMarket allTotalNews bg-white space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-8 mb-4 mb-lg-0">
                        <div class="marketTitle mobBetween d-flex align-items-center">
                            <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)" class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none filter-dropdown">{{marketTab}} <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                            <ul :class="dropdownMenu?'show':''" class="dropdown_menu_animated onMbile toplinks visible pb-2 d-flex align-items-center border-bottom allnewsTabs"
                                v-if="mainTab == 'All' || mainTab == 'trending'">
                                <li class="ps-md-0"><a href="javascript:void(0)"
                                        @click="marketTab = 'All';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'All'}]">All</a></li>
                                <li><a href="javascript:void(0)"
                                        @click="marketTab = 'Forex';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'Forex'}]">Forex</a> </li>
                                <li><a href="javascript:void(0)"
                                        @click="marketTab = 'Indices';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'Indices'}]">Indices</a> </li>
                                <li><a href="javascript:void(0)"
                                        @click="marketTab = 'Commodities';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'Commodities'}]">Commodities</a> </li>
                                <li><a href="javascript:void(0)"
                                        @click="marketTab = 'Crypto';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'Crypto'}]">Crypto</a></li>
                                <!-- <li class="d-none d-xl-block"><a href="javascript:void(0)" @click="marketTab = 'Bonds';page= 1;allnews()" :class= "[{'active' : marketTab == 'Bonds'}]">Bonds</a></li> -->
                                <li><a href="javascript:void(0)"
                                        @click="marketTab = 'Economy';page= 1;filterOpen.country=false;clearCountries();allnews()"
                                        :class="[{'active' : marketTab == 'Economy'}]">Economy</a></li>
                            </ul>
                            <ul class="toplinks mobBorder visible allnewsTabs pb-2 border-bottom"
                                v-if="mainTab == 'All' || mainTab == 'trending'">
                                <li class="position-relative">
                                    <a @click="() => toggleFilter('country')" href="javascript:void(0)"
                                        :class="dropDown? 'active' : ''" class="d-flex align-items-center"> Country
                                        <vue-feather size="20px" type="chevron-down"></vue-feather>
                                    </a>
                                    <!-- <div class="dropdown_menu_animated countryList scrollbar" :class="dropDown?'show':''" v-for="item,key in tab_ViseNews"> -->
                                    <div class="custom-dropdown" :class="filterOpen.country ? 'active' : ''">
                                        <div
                                            class="filterHeader border-bottom p-3 d-flex align-items-center justify-content-between drop-1199">
                                            <!-- <span class="d-flex flex-shrink-0 me-3 me-xl-0" ><img src="assets/images/check-all.svg" alt="Check" title="Check All"/></span> -->
                                            <div class="position-relative">
                                                <a @click.prevent="dropdownMenu2=!dropdownMenu2"
                                                    href="javascript:void(0)"
                                                    class="pt-0 border-btn d-inline-flex align-items-center justify-content-between d-xl-none ms-2">
                                                    All <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                                                </a>
                                                <ul class="dropdown_menu_animated onMbile pad-0 selectTab px-2 d-md-flex align-items-center mb-0"
                                                    :class="dropdownMenu2 ? 'show' : ''">
                                                    <li v-for="option of countryFilterOptions"><a
                                                            href="javascript:void(0)"
                                                            :class="{active: currentCountryFilter==option,}"
                                                            @click="()=> selectCountriesFromTab(option)">{{option}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <a @click.prevent="filterOpen.country=false" href="javascript:void(0)"
                                                class="clearAll d-inline-flex flex-shrink-0">
                                                <vue-feather type="x" size="20"></vue-feather>
                                            </a>
                                        </div>
                                        <!-- <form class="searchBar"> <div class="form-group position-relative inputIcon mb-0"> <input type="text" class="form-control" placeholder="Search.." name=""/> <a class="searchButton" href="#" ><vue-feather type="search"></vue-feather> </a> </div></form> -->
                                        <div class="filter-body p-3 border-bottom">
                                            <ul
                                                class="d-flex align-items-center flex-wrap country scrollbarFilter flex-wrap mb-0">
                                                <li v-for="country of allCountries" class="active">
                                                    <label class="customCheckbox text-start">
                                                        {{country}}<input type="checkbox"
                                                            @click="()=> toggleSelection(country)"
                                                            :checked="inSelectedCountries(country)" /> <span
                                                            class="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            class="filter-footer p-3 d-flex align-items-center justify-content-between flex-wrap">
                                            <label class="customCheckbox">Save setting in my filter <input
                                                    type="checkbox" /> <span class="checkmark"></span> </label>
                                            <div class="d-flex align-items-center">
                                                <a href="javascript:void(0)" @click="()=> clearCountries()"
                                                    class="none-before red me-3">Clear</a>
                                                <a href="javascript:void(0)" @click="()=> allnews()"
                                                    class="none-before green">Apply Filter</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <ul class="d-flex flex-wrap settingDropdown">
                                        For responsive
                                        <li class="d-xl-none"><a href="javascript:void(0)">Commodities</a></li>
                                        <li class="d-xl-none"><a href="javascript:void(0)">Crypto</a></li>
                                        <li class="d-xl-none"><a href="javascript:void(0)">Bonds</a></li>
                                        <li class="d-xl-none"><a href="javascript:void(0)">Economy</a></li>
                                        <li class="d-xl-none"><a href="javascript:void(0)">Country</a></li>
                                        End
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">Europe</a></li>
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">America</a></li>
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">Asia</a></li>
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">Africa</a></li>
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">Australia</a></li>
                                        <li><a href="javascript:void(0)" class="d-flex align-items-center">G20</a></li>
                                    </ul> -->
                                    <!-- </div> -->
                                </li>
                            </ul>
                        </div>
                        <div class="rotateNews" v-show="tab_ViseNews.totalRecords && tab_ViseNews.totalRecords >  0">
                            <div class="card-body d-flex align-items-start border-bottom mb-2 px-0"
                                v-for="item,key in tab_ViseNews.records">
                                <span class="mb-2 mb-md-0"><img width="300" :src="item.image" alt="News"
                                        title="News"></span>
                                <!-- <span class="flex-shrink-0 mb-2 mb-md-0 w-auto"><img src="assets/images/news/newsblock-12.png" alt="News" title="News"></span> -->
                                <div class="flex-grow-1 ms-0 ms-md-3" :id="'news'+item._id">
                                    <h6 @click="()=> goToDetailPage(item)"><a
                                            href="javascript:void(0)">{{item.title}}</a></h6>
                                    <p v-html="item.description.slice(0,150)" v-if="item.description"></p>
                                    <ul class="datTime d-flex align-items-center">
                                        <!--  <li class="me-4"><a href="javascript:void(0)">{{item.source}} </a></li> -->
                                        <li>{{formatNow(item.published,'now',0)}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <no-record v-if="!tab_ViseNews.totalRecords"></no-record>
                        <div class="d-flex justify-content-center"
                            v-if="tab_ViseNews.records && (tab_ViseNews.records.length < tab_ViseNews.totalRecords)">
                            <p class="grd_btn" v-if="store.loading"><i class="me-2 fa fa-spinner fa-spin"></i></p>
                            <a href="javascript:void(0);" class="grd_btn" @click="() => loadMore()" v-else>Load More</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="position-sticky topAdd">
                            <div class="economicAllNews mb-4 mb-lg-5">
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                    <h3 class="m-0">Economic Calendar</h3>
                                    <router-link to="/economic-calendar" class="green_btn d-flex align-items-center">See
                                        All <vue-feather size="12px" type="chevron-right" class="chevron-right">
                                        </vue-feather>
                                    </router-link>
                                </div>
                                <div v-if="economicData.length" class="table-responsive economic">
                                    <table v-for="(row, indexOuter) of economicData"
                                        class="table table-sm border-0 bg-white" :class="{'border-top': indexOuter > 0}"
                                        :key="row.date">
                                        <thead>
                                            <tr role="button" @click="()=> toggleOuter(indexOuter)">
                                                <th class="date medium">{{COMMON.formatDate(row.date, "Do MMMM")}}</th>
                                                <th colspan="3" class="events text-end">{{row.items.length}}events</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="activeOuter==indexOuter">
                                            <tr role="button" v-for="(event, indexInner) of rowInnerList"
                                                @click="()=> toggleInner(indexInner, event)" class="borderAdd"
                                                :class="{active: activeInner==indexInner, rt: event.importance==1, et: event.importance==2, bt: event.importance==3,}"
                                                :key="event._id">
                                                <td class="p-0 border-0" colspan="4">
                                                    <table width="100%" class="fixed-layout nowrap">
                                                        <tr>
                                                            <td :class="{'active-td': activeInner==indexInner}">
                                                                <span class="d-flex align-items-center">
                                                                    <img width="25" height="25"
                                                                        :src=" 'assets/images/country_flag/' + event.country.toLowerCase() + '.webp' "
                                                                        class="rounded-circle me-2" alt="" /><span
                                                                        class="ellipsis">{{event.country}}</span>
                                                                </span>
                                                            </td>
                                                            <td>{{COMMON.formatDate(event.date, "hh:mm a")}}</td>
                                                            <td>{{event.event}}</td>
                                                            <td>
                                                                <a class="d-block text-end" href="javascript:void(0);">
                                                                    <vue-feather v-if="activeInner !=indexInner"
                                                                        type="chevron-down"></vue-feather>
                                                                    <vue-feather v-if="activeInner==indexInner"
                                                                        type="chevron-up"></vue-feather>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr class="subactive-tr" v-if="activeInner==indexInner">
                                                            <td colspan="4">
                                                                <div
                                                                    class="d-flex align-items-center justify-content-between openDom">
                                                                    <span class="subactive-td">
                                                                        <div class="f-16">{{COMMON.formatCalendarDate(
                                                                        activeEvent.date, "duration")}}</div>
                                                                        <div class="f-12">Time{{COMMON.formatDate(
                                                                        activeEvent.date, "HH:mm")}}</div>
                                                                    </span>
                                                                    <span align="center"
                                                                        class="active-time-int middle">Actual{{activeEvent.actual
                                                                        ? activeEvent.actual : "-"}}</span>
                                                                    <span colspan="2" class="subactive-td2">
                                                                        <div class="forecast"><span
                                                                                class="f-12 me-2">Forecast</span> <span
                                                                                class="f-16">{{activeEvent.teForecast ?
                                                                                activeEvent.teForecast : "-"}}</span>
                                                                        </div>
                                                                        <div class="previe"><span
                                                                                class="f-12 me-2">Previous</span> <span
                                                                                class="f-16">{{activeEvent.previous ?
                                                                                activeEvent.previous : "-"}}</span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <no-record v-else></no-record>
                            </div>
                            <div class="marketTitle">
                                <h3 class="mb-3">Most Read</h3>
                            </div>
                            <ul class="mostRead" v-if="store.ReadNews.records && store.ReadNews.records.length > 0">
                                <li v-for="item,key in store.ReadNews.records.slice(0,7)" :key="key" :id="'news'+item._id"><a
                                        href="javascript:void(0)"
                                        @click="()=> goToDetailPage(item)">{{item.title}}</a></li>
                            </ul>
                            <no-record v-else></no-record>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Open Modal -->
        <!--  <div class="modal fade ideaModal" v-if="Object.keys(showNews).length > 0" :class="(Object.keys(showNews).length > 0) ? 'show' : ''">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="row modal-content g-0 h-100">
                <div class="col-12 col-lg-6 ideaChart">
                    <img class="d-block w-100 h-100" :src="showNews.image" alt="News" title="News Image" >
                </div>  
                <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                    <div class="modal-body p-4 pt-0 border-0">
                        <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                            <ul class="d-flex align-items-center mb-0">
                                <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                                <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                            </ul>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showNews = {}"></button>
                        </div>
                        <ul class="datTime d-flex align-items-center mb-0">
                            <li class="me-4"><a class="green" href="javascript:void(0)">{{showNews.source}}</a></li>
                            <li>{{formatNow(showNews.published,'now',0)}}</li>
                        </ul>
                        <h2 class="medium my-4 py-2" >{{showNews.title}}</h2>
                        <p v-html="showNews.description"></p>
                    </div>
                </div>
            </div>
          </div>
        </div> -->
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import bannerslider from "../../components/shared/banner_slider";
import loading from "../loader/loader.vue";
import moment from 'moment';
import $ from 'jquery'
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            activeOuter: 0,
            activeInner: 0,
            countryFilterOptions: [
                "All",
                "Major",
                "Asia",
                "America",
                "Europe",
                "Australia",
            ],
            filters: {
                impact: 0,
                countries: [],
                dates: {
                    from: moment().format("YYYY-MM-DD"),
                    to: moment().add(7, "days").format("YYYY-MM-DD"),
                },
            },
            currentCountryFilter: "All",
            filterOpen: {
                timezone: false,
                impact: false,
                country: false,
            },
            selectedCountries: [],
            addFilter: false,
            dropdownMenu: false,
            marketTab: 'All',
            //activeEvent: {},
            dropDown: false,
            slice: 6,
            mainTab: 'All',
            dropdownMenu2: false,
            page: 1,
            perPage: 7
        }
    },
    mounted() {
        const _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".filter-dropdown").length) {
                _that.dropdownMenu = false;
            }
        });
        if (this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'news-detail') {
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('news' + data[2])
            this.$router.options.history.state.forward = ""
            this.page = parseInt(data[4])
        } else {
            this.page = 1
            this.allnews()
        }


        this.selectedCountries =
            this.static_vars.economicCalendarCountries[
            this.currentCountryFilter.toLowerCase()
            ];
        this.selectCountriesFromTab('All')
    },
    computed: {
        allCountries() {
            let arr = [];
            for (let key in this.static_vars.economicCalendarCountries) {
                this.static_vars.economicCalendarCountries[key].map((i) => {
                    arr.push(i);
                });
            }
            return [...new Set(arr)].sort((a, b) => a.localeCompare(b));
        },
        tab_ViseNews() {
            if (this.mainTab == 'popular') {
                return this.store.popularNews
            } else if (this.mainTab == 'trending') {
                if (this.marketTab == 'Economy') {
                    return this.store.alleconomyNews
                } else {
                    return this.store.trendingNews
                }
            } else if (this.mainTab == 'mostRead') {
                return this.store.ReadNews
            } else {
                if (this.marketTab == 'Forex') {
                    return this.store.ForexNews
                } else if (this.marketTab == 'Crypto') {
                    return this.store.CryptoNews
                } else if (this.marketTab == 'Indices') {
                    return this.store.IndicesNews
                } else if (this.marketTab == 'Commodities') {
                    return this.store.CommoditiesNews
                } else if (this.marketTab == 'Bonds') {
                    return this.store.bondNews
                } else if (this.marketTab == 'Economy') {
                    return this.store.alleconomyNews
                } else {//country
                    return this.store.newsList
                }
            }
        },
        dateAdjustedEvents() {
            if (Object.keys(this.store.calendarData).length) {
                let temp = [],
                    allEvents = [];
                for (let key in this.store.calendarData) {
                    this.store.calendarData[key].items.map((i) => {
                        let obj = Object.assign({}, i);
                        obj.date = this.COMMON.formatCalendarDate(
                            obj.date,
                            "YYYY-MM-DD HH:mm",
                            0
                        );
                        //console.log(obj);
                        temp.push(obj);
                    });
                }
                temp.map((i, index) => {
                    const ind = allEvents.findIndex(
                        (j) =>
                            moment(j.date).format("YYYY-MM-DD") ==
                            moment(i.date).format("YYYY-MM-DD")
                    );
                    if (ind > -1) {
                        if (allEvents[ind].items) {
                            allEvents[ind].items.push(i);
                        } else {
                            allEvents[ind].items = [i];
                        }
                    } else {
                        allEvents.push({
                            date: moment(i.date).format("YYYY-MM-DD"),
                            items: [i],
                        });
                    }
                });
                return allEvents;
            } else {
                return [];
            }
        },
        economicData() {
            if (this.dateAdjustedEvents.length) {
                let arr = [];
                this.dateAdjustedEvents.map((i) => {
                    let temp = {
                        date: i.date,
                        items: [],
                    };
                    if (i.items.length) {
                        temp.items = i.items.filter((j) => {
                            return (
                                moment(
                                    this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                                ).diff(moment(), "minute") > 5
                            );
                        });
                    }
                    if (temp.items.length) arr.push(temp);
                });
                return arr.slice(0, 5);
                // return arr;
            } else {
                return [];
            }
        },
        rowInnerList() {
            if (
                this.activeOuter > -1 &&
                this.economicData[this.activeOuter].items &&
                this.economicData[this.activeOuter].items.length
            ) {
                let arr = [];

                arr = this.economicData[this.activeOuter].items.filter((j) => {
                    return (
                        j.importance > 1 &&
                        moment(
                            this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                        ).diff(moment(), "minute") > 5
                    );
                });
                return arr.slice(0, 5);
            } else {
                return [];
            }
        },
        activeEvent() {
            if (this.activeOuter > -1 && this.activeInner > -1)
                return this.rowInnerList[this.activeInner];
        },
    },
    methods: {
        toDivElement(id) {
            try {
                setTimeout(function () {
                    var elmnt = document.getElementById(id);
                    if(elmnt){
                        elmnt.scrollIntoView({ behavior: "smooth" });
                        document.getElementById(id).focus();
                        //var top = $(window).scrollTop()
                        // console.log(id,'top',top)
                        // $(window).scrollTop(top-80)
                    }
                }, 500);
            } catch (e) { }
        },
        goToDetailPage(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'news_details',
                params: { 'id': item._id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': this.page }
            });
            /*/ +/g, '-'*/
        },
        calnewsDetail() {
            let form = {
                '_id': this.showNews._id
            }
            this.store
                .callNewsDetails(form, false)
                .then((response) => {
                })
                .catch();
        },
        loadMore() {
            this.page += 1
            this.allnews('loadmore')
        },
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        clearCountries() {
            this.selectedCountries = [];
        },
        toggleFilter(key) {
            this.marketTab = ''
            this.selectCountriesFromTab('All')
            for (let k in this.filterOpen) {
                if (k == key) {
                    this.filterOpen = Object.assign(this.filterOpen, {
                        [k]: !this.filterOpen[key],
                    });
                } else {
                    this.filterOpen = Object.assign(this.filterOpen, { [k]: false });
                }
            }
        },
        toggleSelection(country) {
            let ind = this.selectedCountries.findIndex((i) => i === country);
            if (ind > -1) {
                this.selectedCountries.splice(ind, 1);
            } else {
                this.selectedCountries.push(country);
            }
        },
        selectCountriesFromTab(tab) {
            this.currentCountryFilter = tab;
            if (tab == "All") {
                let arr = [];
                for (let key in this.static_vars.economicCalendarCountries) {
                    this.static_vars.economicCalendarCountries[key].map((i) => {
                        arr.push(i);
                    });
                }
                this.selectedCountries = [...new Set(arr)];
            } else {
                this.selectedCountries = [
                    ...new Set(
                        this.static_vars.economicCalendarCountries[tab.toLowerCase()]
                    ),
                ];
            }
        },
        inSelectedCountries(country) {
            try {
                if (this.selectedCountries.includes(country)) return true;
                return false;
            } catch (error) {
                console.log(error);
            }
        },
        toggleOuter(index) {
            if (this.activeOuter == index) {
                this.activeOuter = -1;
                this.activeInner = -1;
            } else {
                this.activeOuter = index;
                this.activeInner = -1;
            }
        },
        toggleInner(index, event) {
            if (this.activeInner == index) {
                this.activeInner = -1;
                // this.activeEvent = {};
            } else {
                this.activeInner = index;
                // this.activeEvent = event;
            }
        },
        allnews(from) {
            if (!from) {
                this.$router.replace({
                    path: this.$route.path,
                    query: { 'type': this.mainTab, 'news': (this.marketTab) ? this.marketTab : 'All' },
                });
            }

            let formData = {
                page: this.page,
                perPage: 7,
            };
            if (this.mainTab == 'mostRead') {
                formData['type'] = 'most_read'
                this.store.callNewsList(formData, true).then(() => {
                }).catch(err => console.log(err.message))
            } else if (this.mainTab == 'popular') {
                formData['from'] = 'All-News'
                this.store.callPopularNews(formData, true).then(() => {
                }).catch(err => console.log(err.message))
            } else if (this.mainTab == 'trending') {
                formData['from'] = 'All-News'
                if (this.selectedCountries.length > 0) {
                    formData['country'] = this.selectedCountries
                } else if (this.marketTab == 'Forex') {
                    formData['category_type'] = 'FOREX'
                } else if (this.marketTab == 'Crypto') {
                    formData['category_type'] = 'CRYPTO'
                } else if (this.marketTab == 'Indices') {
                    formData['category_type'] = 'INDICES'
                } else if (this.marketTab == 'Commodities') {
                    formData['category_type'] = 'COMMODITIES'
                } else if (this.marketTab == 'Bonds') {
                    formData['category_type'] = 'BONDS'
                } else if (this.marketTab == 'Economy') {
                    formData['category_type'] = 'ECONOMY'
                }
                this.store.callTrendingNews(formData, true).then(() => {
                }).catch(err => console.log(err.message))
            } else {
                if (this.selectedCountries.length > 0) {
                    formData['country'] = this.selectedCountries
                } else if (this.marketTab == 'Forex') {
                    formData['category_type'] = 'FOREX'
                } else if (this.marketTab == 'Crypto') {
                    formData['category_type'] = 'CRYPTO'
                } else if (this.marketTab == 'Indices') {
                    formData['category_type'] = 'INDICES'
                } else if (this.marketTab == 'Commodities') {
                    formData['category_type'] = 'COMMODITIES'
                } else if (this.marketTab == 'Bonds') {
                    formData['category_type'] = 'BONDS'
                } else if (this.marketTab == 'Economy') {
                    formData['category_type'] = 'ECONOMY'
                }
                this.store.callNewsList(formData, true).then(() => {
                    this.filterOpen.country = false
                }).catch(err => console.log(err.message))
            }
        },
        mostRead() {
            let formData = {
                page: this.page,
                perPage: 15,
                type: 'most_read'
            };
            this.store.callNewsList(formData, true).then(() => {
            }).catch(err => console.log(err.message))
        },

        getCalendar() {
            let formData = {
                page: this.page,
                perPage: 15,
            };
            this.store
                .getCalendar(formData, false)
                .then(() => {
                })
                .catch();
        },

    },
    components: {
        bannerSlider: bannerslider,
        loading,

    },
    created() {
        //console.log(this.$router.options.history)
        if (this.$route.query.type) {
            this.mainTab = this.$route.query.type
        }
        if (this.$route.query.news) {
            //this.mainTab = 'All'
            this.marketTab = this.$route.query.news
        }
        // if(this.$route.query.from){   
        //     let query = this.$router.currentRoute._value.query
        //     this.toDivElement('news'+this.$route.query.id)
        //     //this.$router.replace({path :this.$route.path,query:{type : query.type,news:query.news}})
        // }else{
        //     this.allnews()
        // }

        this.mostRead()
        this.getCalendar()
    }
}
</script>
